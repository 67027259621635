<template>
  <div>
    <div class="tw-pb-10 tw-relative">
      <button
        v-b-modal.tambah-live
        class="tw-rounded-[50%] tw-absolute tw-bottom-0 tw-right-10 tw-w-[50px] tw-bg-[#5022E2] tw-border-0 tw-flex tw-justify-center tw-items-center tw-h-[50px]"
      >
        <feather-icon class="tw-text-white" icon="PlusIcon" />
      </button>

      <div class="tw-flex tw-flex-wrap tw-gap-5">
        <b-link v-for="item in data.details" :key="item.id">
          <card
            :id="item.id"
            :onPlay="() => getLink(item.link)"
            :onDelete="() => showDelete(item.id)"
            :onEdit="() => getDetail(item)"
            :title="item.nama"
            :deskripsi="item.deskripsi"
          />
        </b-link>
      </div>
    </div>

    <!-- Add/Edit Live -->
    <b-modal
      id="tambah-live"
      size="md"
      ref="modalForm"
      centered
      :title="isEditing ? 'Edit Kelas Live' : 'Tambah Kelas Live'"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="onHidden"
      @ok="submitLive"
    >
      <b-form>
        <b-form-group label="Nama Kelas Live" label-for="nama">
          <b-form-input
            v-model="form.nama"
            id="nama"
            placeholder="Masukkaan Nama Kelas Live"
          />
        </b-form-group>
        <b-form-group label="Link Kelas Live" label-for="link">
          <b-form-input
            v-model="form.link"
            id="link"
            placeholder="Masukkaan Link Kelas Live"
          />
        </b-form-group>

        <b-form-group label="Deskripsi Kelas Live" label-for="deskripsi">
          <b-form-input
            v-model="form.deskripsi"
            id="deskripsi"
            placeholder="Masukkaan Deskripsi Kelas Live"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Delete Detail Product -->
    <b-modal
      id="hapus-detail-product"
      ref="modalDelete"
      cancel-variant="outline-secondary"
      :ok-title="isLoadingSubmit ? 'Loading...' : 'Hapus'"
      cancel-title="Close"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      centered
      title="Hapus Detail Product"
      @ok="deleteProduct"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda yakin inin menghapus Product ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import repository from "@repofactory";
const repoProducts = repository.get("ProductsRepository")
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import Card from '@core/components/video/Card.vue';
import router from '@/router';

import {
  BButton,
  BRow,
  BCol,
  BLink,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

export default defineComponent({
  components: {
    BButton,
    FeatherIcon,
    BRow,
    BCol,
    BLink,
    Card,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const { route } = useRouter();
    const toast = useToast();

    const data = ref([])
    const isLoadingSubmit = ref(false)
    const isEditing = ref(false)

    const modalForm = ref(null)
    const modalDelete = ref(null)

    const detailProductId = ref(null)

    const getDetail = async (data) => {
      isEditing.value = true
      detailProductId.value = data.id
      form.value = {
        nama: data.nama,
        deskripsi: data.deskripsi,
        link: data.link,
        preview_image: data.preview_image
      }
      modalForm.value.show()
    }

    const showDelete = async (id) => {
      detailProductId.value = id
      modalDelete.value.show()
    }

    const deleteProduct = async (modal) => {
      try {
        modal.preventDefault()
        isLoadingSubmit.value = true
        const res = await repoProducts.deleteDetail(detailProductId.value)
        showToast("Notifikasi", res.data.message, "primary");
        modal.vueTarget.hide()
        await fetchData()
      } catch (e) {
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
            showToast(
            "Error",
            "Error Set Type Products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false
      }
    }

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const form = ref({
        nama: null,
        link: null,
        deskripsi: null,
        preview_image: null
      })

    const resetModal = () => {
      if(!isEditing.value) {
        form.value = {
          nama: null,
          link: null,
          deskripsi: null,
          preview_image: null
        }
      }
    }

    const onHidden = () => {
      isEditing.value = false
      form.value = {
          nama: null,
          link: null,
          deskripsi: null,
          preview_image: null
        }
    }

    const fetchData = async () => {
      try {
        const res = await repoProducts.getDetail(route.value.params.id)
        data.value = res.data.data
      } catch (e) {
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
          } else {
              showToast(
              "Error",
              "Error Set Type Products",
              "danger",
              "AlertTriangleIcon"
            );
          }
      }
    }

    onMounted(() => {
      fetchData()
    })

    const getLink = (link) => {
      window.open(link, '_blank');
    }

    const submitLive = async (modal) => {
      try {
        modal.preventDefault()
        isLoadingSubmit.value = true
        let res;
        if(isEditing.value) {
          res = await repoProducts.updateDetail(detailProductId.value, form.value)
        } else {
          res = await repoProducts.createDetail(route.value.params.id, form.value)
        }
        showToast("Notifikasi", res.data.message, "primary");
        modal.vueTarget.hide()
        await fetchData()
      } catch (e) {
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
            showToast(
            "Error",
            "Error Submit data products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false
        isEditing.value = false
      }
    }

    return {
      data,
      isLoadingSubmit,
      form,
      modalForm,
      isEditing,
      detailProductId,
      modalDelete,

      // method
      resetModal,
      submitLive,
      getDetail,
      showDelete,
      deleteProduct,
      getLink,
      onHidden,
    }
  },
})
</script>

<style scoped>
.container {
  height: 100vh;
  overflow: auto;
}
p {
  margin-bottom: 5px;
}
</style>
