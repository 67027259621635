import RingkasanMateri from "@/views/dashboard/manajemen-materi/ringkasan-materi/index.vue";
import Detail from "@/views/dashboard/manajemen-materi/ringkasan-materi/detail.vue";
import Preview from "@/views/dashboard/manajemen-materi/ringkasan-materi/preview.vue";

export default [
  {
    path: "/ringkasan-materi",
    name: "ringkasan-materi",
    component: RingkasanMateri,
    meta: {
      pageTitle: "Manajemen Materi",
      breadcrumb: [
        {
          text: "Ringkasan Materi",
          active: true,
        },
      ],
    },
  },

  {
    path: "/ringkasan-materi/:id",
    name: "detail-ringkasan-materi",
    component: Detail,
    meta: {
      pageTitle: "Manajemen Materi",
      breadcrumb: [
        {
          text: "Ringkasan Materi",
          active: false,
          to: {name: "ringkasan-materi"},
        },
        {
          text: "List Ringkasan",
          active: true,
        },
      ],
    },
  },

  {
    path: "/ringkasan-materi/:id/preview/:detail_id",
    name: "preview-ringkasan-materi",
    component: Preview,
    meta: {
      pageTitle: "Manajemen Materi",
      breadcrumb: [
        {
          text: "Ringkasan Materi",
          active: false,
          to: {name: "ringkasan-materi"},
        },
        {
          text: "List Ringkasan",
          active: false,
          to: {name: "detail-ringkasan-materi"},
        },
        {
          text: "Preview Ringkasan",
          active: true,
        },
      ],
    },
  },
];
