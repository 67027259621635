<template>
  <div
    :class="`container-shadow tw-w-[300px] tw-h-[300px] tw-overflow-hidden tw-flex tw-flex-col tw-p-[21px] tw-mb-3 tw-min-h-[359.58px] tw-rounded-[13.65px] tw-bg-white`"
  >
    <!-- Thumbnail -->
    <div
      :style="{
        background: `url(${this.thumbnail || '/assets/images/dashboard/card/bg.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
      :class="`tw-flex tw-justify-end tw-rounded-[13.65px] tw-h-[205px] tw-p-[12px] tw-w-full`"
    >
      <div class="tw-flex tw-w-full tw-justify-between">
        <button
          @click="this.onEdit"
          class="tw-w-[34px] tw-h-[34px] tw-border-0 tw-bg-white tw-rounded-[50%]"
        >
          <feather-icon
            icon="Edit2Icon"
            size="18"
            :badgeClasses="'tw-text-[#5022E2]'"
          />
        </button>

        <button
          @click="this.onPlay"
          class="tw-w-[34px] tw-h-[34px] tw-border-0 tw-bg-white tw-rounded-[50%]"
        >
          <feather-icon
            icon="PlayIcon"
            size="18"
            :badgeClasses="'tw-text-[#5022E2]'"
          />
        </button>

        <button
          @click="this.onDelete"
          class="tw-w-[34px] tw-h-[34px] tw-border-0 tw-bg-red-500 tw-text-white tw-rounded-[50%]"
        >
          <feather-icon
            icon="Trash2Icon"
            size="18"
            :badgeClasses="'tw-text-[#5022E2]'"
          />
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="tw-mt-[19px] tw-text-[#1B2559] tw-text-[16px]">
      <p class="tw-font-semibold tw-text-ellipsis tw-overflow-hidden">
        {{ this.title }}
      </p>

      <p
        class="tw-text-[#A3AED0] tw-h-[100px] tw-text-ellipsis tw-overflow-hidden tw-mt-[5px] tw-mb-[19px]"
      >
        {{ this.deskripsi }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { rupiah } from '@core/utils/utils'
import {
  BButton,
} from "bootstrap-vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default defineComponent({
    components: {
        BButton,
        FeatherIcon,
    },
    props: {
        thumbnail: String,
        title: String,
        deskripsi: String,
        onEdit: Function,
        onDelete: Function,
        onPlay: Function
    },

    setup() {
        return {
            rupiah,
        }
    },
})
</script>

<style scoped>
.container-shadow {
  box-shadow: 0px 4px 40px 0px #0000001A;
}

p {
    margin-bottom: 0;
}
</style>
