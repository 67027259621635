export default [
  {
    path: "/bank-soal",
    name: "bank-soal",
    meta: {
      pageTitle: "Manajemen Soal",
      breadcrumb: [
        {
          text: "Bank Soal",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/dashboard/bank-soal/manajemen-soal/bank-soal/index.vue"),
  },
  {
    path: "/bank-soal/:id",
    name: "bank-soal-detail",
    meta: {
      pageTitle: "Manajemen Soal",
      breadcrumb: [
        {
          text: "Bank Soal",
          active: false,
          to: "/bank-soal",
        },
        {
          text: "Detail Soal",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/dashboard/bank-soal/manajemen-soal/bank-soal/detail.vue"),
  },
];
