import Client from './Clients/AxiosClient'
const resource = '/api/v1/kampus'

export default {
  get(id_kampus, id_jurusan) {
    return Client.get(`${resource}/${id_kampus}/jurusan/${id_jurusan}`)
  },
  create(payload, id_kampus, id_jurusan) {
    return Client.post(`${resource}/${id_kampus}/jurusan/${id_jurusan}/daya-tampung`, payload)
  },
  update(payload, id_kampus, id_jurusan, id_daya_tampung) {
    return Client.put(`${resource}/${id_kampus}/jurusan/${id_jurusan}/daya-tampung/${id_daya_tampung}`, payload)
  },
  delete(id_kampus, id_jurusan, id_daya_tampung) {
    return Client.delete(`${resource}/${id_kampus}/jurusan/${id_jurusan}/daya-tampung/${id_daya_tampung}`)
  },
}
