import router from "@/router";
// eslint-disable-next-line object-curly-newline
import {
  reactive,
  getCurrentInstance,
  watch,
  toRefs,
} from "@vue/composition-api";

export const isObject = obj => typeof obj === "object" && obj !== null;

export const isToday = date => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

export const padder = value => {
  return `0${value}`.slice(-2);
};

export const formatDate = value => {
  const d = new Date(value);
  const date = padder(d.getDate());
  const month = padder(d.getMonth() + 1);
  const year = d.getFullYear();
  const hour = padder(d.getHours());
  const minutes = padder(d.getMinutes());
  const seconds = padder(d.getSeconds());
  return `${date}-${month}-${year}, ${hour}:${minutes}:${seconds}`;
};

export const formatDatePayload = value => {
  const d = new Date(value);
  const date = padder(d.getDate());
  const month = padder(d.getMonth() + 1);
  const year = d.getFullYear();
  const hour = padder(d.getHours());
  const minutes = padder(d.getMinutes());
  return `${year}-${month}-${date} ${hour}:${minutes}`;
};

const getRandomFromArray = array =>
  array[Math.floor(Math.random() * array.length)];

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const {route: resolvedRoute} = router.resolve(route);
  return resolvedRoute.path === router.currentRoute.path;
};

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy;
  const state = reactive({
    route: vm.$route,
  });

  watch(
    () => vm.$route,
    r => {
      state.route = r;
    }
  );

  return {...toRefs(state), router: vm.$router};
};

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const downloadFile = async (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url;

  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const rupiah = x => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(x); // '$100.00'
};
