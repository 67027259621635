export default [
  {
    path: "/voucher",
    name: "voucher",
    meta: {
      pageTitle: "Voucher",
      breadcrumb: [
        {
          text: "Voucher",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/voucher/index.vue"),
  },
];
