<template>
  <div class="tw-bg-white tw-px-[47px] tw-py-[32px] tw-rounded-[30px]">
    <div class="tw-flex tw-mb-[30px] tw-justify-center">
      <img
        class=""
        width="300"
        src="/assets/images/ebook/book.png"
        alt="book.png"
      />
    </div>

    <main>
      <h1
        class="tw-font-bold text-center tw-text-2xl tw-leading-8 tw-mb-[27px]"
      >
        {{data.nama}}
      </h1>

      <p class="tw-text-lg tw-leading-8">
        {{data.deskripsi}}
      </p>

      <p class="tw-font-bold">
        Link : <a :href="data.link">{{data.link}}</a>
      </p>
    </main>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import repository from "@repofactory";
const repoProducts = repository.get("ProductsRepository")

import { useRouter } from "@core/utils/utils";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default defineComponent({
    components: {

    },
    setup() {
        const { route } = useRouter();
        const toast = useToast();

        const data = ref(null)

        const showToast = (title, text, variant, icon = "BellIcon") => {
            toast({
                component: ToastificationContent,
                props: {
                title,
                icon,
                text,
                variant,
                },
            });
        };

        const fetchData = async () => {
            try {
                const res = await repoProducts.getDetailsDetail(route.value.params.id, route.value.params.detail_id)
                data.value = res.data.data
            } catch (e) {
                if(e.response.data.message) {
                    showToast(
                    "Error",
                    e.response.data.message,
                    "danger",
                    "AlertTriangleIcon"
                );
                } else {
                    showToast(
                    "Error",
                    "Error Set Type Products",
                    "danger",
                    "AlertTriangleIcon"
                    );
                }
            }

        }
        onMounted(() => {
            fetchData()
        })

        return {
            data,
        }
    },
})
</script>
