import Client from "./Clients/AxiosClient";
const resource = "/api/v1/paket-kategori";

export default {
  get(page = 1, keyword = "", kategori = "", perPage = 0) {
    let filters = "&filters=";
    if (!!kategori) {
      filters += `kategori:${kategori}`;
    }
    return Client.get(
      `${resource}?page=${page}&search=${keyword}&per_page=${perPage}${filters}`
    );
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.post(`${resource}/${id}?_method=PUT`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
