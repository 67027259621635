<template>
  <b-row align-v="center">
    <b-col cols="12" sm="6">
      <span class="text-muted"
        >Showing {{ this.from }} to {{ this.to }} of
        {{ this.total }} entries</span
      >
    </b-col>
    <!-- Pagination -->
    <b-col class="tw-flex tw-items-end tw-justify-end" cols="12" sm="6">
      <b-pagination-nav
        :link-gen="linkGen"
        use-router
        :number-of-pages="this.pageTotal"
      />
    </b-col>
  </b-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BPaginationNav,
} from "bootstrap-vue";

export default defineComponent({
    components: {
        BRow,
        BCol,
        BPaginationNav
    },
    props: {
        from: Number,
        to: Number,
        total: Number,
        pageTotal: Number,
    },
    setup() {
        const linkGen = (pageNum) => {
            return pageNum === 1 ? "?" : `?page=${pageNum}`;
        };

        return {
            linkGen
        }
    },
})
</script>

<style>

.b-pagination {
    margin-bottom: 0 !important;
}
</style>
