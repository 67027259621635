import Client from "./Clients/AxiosClient";
const resource = "/api/v1/admin/vouchers";

export default {
  get(page, search) {
    return Client.get(resource, {
      params: {
        page,
        limit: 9999,
        search,
      },
    });
  },
  create(payload) {
    return Client.post(resource, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  detail(id) {
    return Client.get(`${resource}/${id}`);
  },
};
