import Client from "./Clients/AxiosClient";
const resource = "/api/v1/download-hasil";
const resourceAdmin = "api/v1/admin/pengerjaan/tryout/report/download";

export default {
  download(id) {
    return Client.get(`${resource}/${id}`, {
      responseType: "blob",
    });
  },

  downloadReport(id) {
    return Client.get(`${resourceAdmin}/${id}`, {
      responseType: "blob",
    });
  },
};
