<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->

      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-between">
        <!-- Search -->
        <search-input
          :placeholder="'Cari Video berdasrkan Nama...'"
          :onInput="onSearch"
        />
        <b-button
          v-b-modal.tambah-product
          style="border-radius: 15px; height: 48px"
          variant="primary"
        >
          <span class="text-sm text-nowrap">Tambah Video</span>
        </b-button>
      </div>
    </div>

    <!-- Filter By -->
    <div class="tw-inline-flex tw-mb-6">
      <custom-filter
        :data="filterList"
        :handleChange="handleChange"
        :filterValue="filterValue"
        :initialValue="initialValue"
      />
    </div>

    <!-- Table Contianer Card -->
    <b-card no-body class="mb-0">
      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Data tidak ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          <p>
            {{formatDate(data.value)}}
          </p>
        </template>

        <template #cell(updated_at)="data">
          <p>
            {{formatDate(data.value)}}
          </p>
        </template>

        <template #cell(harga)="data">
          <p>
            {{rupiah(data.value)}}
          </p>
        </template>

        <template #cell(harga_diskon)="data">
          <p>
            {{rupiah(data.value)}}
          </p>
        </template>

        <template #cell(is_active)="data">
          <p>
            {{!!data.value ? "Aktif" : "Non-aktif"}}
          </p>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'detail-video', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detail Produk</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.edit-product
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.hapus-produk
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="totalPage"
          :total="totalData"
        />
      </div>
    </b-card>

    <!-- Add Product -->
    <b-modal
      id="tambah-product"
      size="md"
      centered
      ref="modalProduct"
      title="Tambahkan Product"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="submitProduct"
    >
      <b-form>
        <b-form-group label="Nama Produk" label-for="nama">
          <b-form-input
            v-model="formProduct.nama"
            id="nama"
            placeholder="Masukkaan Nama Produk"
          />
        </b-form-group>
        <b-form-group label="Nama Jenis" label-for="jenis">
          <v-select
            id="jenis"
            v-model="formProduct.jenis"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="listJenis"
            :reduce="jenis => jenis.value"
          />
        </b-form-group>

        <b-form-group label="Kategori" label-for="category">
          <v-select
            id="category"
            v-model="formProduct.category"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="listCategory"
            :reduce="category => category.value"
          />
        </b-form-group>

        <b-form-group label="Label" label-for="label">
          <b-form-input
            v-model="formProduct.label"
            id="label"
            placeholder="Masukkaan Label Produk"
          />
        </b-form-group>

        <b-form-group
          v-if="formProduct.jenis === 'PREMIUM'"
          label="Harga"
          label-for="harga"
        >
          <b-form-input
            v-model="formProduct.harga"
            id="harga"
            placeholder="Masukkaan Harga Produk"
          />
        </b-form-group>

        <b-form-group
          v-if="formProduct.jenis === 'PREMIUM'"
          label="Harga Diskon"
          label-for="hargaDiskon"
        >
          <b-form-input
            v-model="formProduct.harga_diskon"
            id="hargaDiskon"
            placeholder="Masukkaan Harga Diskon Produk"
          />
        </b-form-group>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="show">Aktif</label>
          <b-form-checkbox
            id="show"
            :checked="formProduct.is_active"
            v-model="formProduct.is_active"
            class=""
            name="check-button"
            switch
          />
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Product -->
    <b-modal
      id="edit-product"
      size="md"
      centered
      title="Edit Product"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="fetchProduct"
      @hidden="resetModal"
      @ok="editProduct"
    >
      <b-form>
        <b-form-group label="Nama Produk" label-for="nama">
          <b-form-input
            v-model="formProduct.nama"
            id="nama"
            placeholder="Masukkaan Nama Produk"
          />
        </b-form-group>
        <b-form-group label="Nama Jenis" label-for="jenis">
          <v-select
            id="jenis"
            v-model="formProduct.jenis"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="listJenis"
            :reduce="jenis => jenis.value"
          />
        </b-form-group>

        <b-form-group label="Kategori" label-for="category">
          <v-select
            id="category"
            v-model="formProduct.category"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="listCategory"
            :reduce="category => category.value"
          />
        </b-form-group>

        <b-form-group label="Label" label-for="label">
          <b-form-input
            v-model="formProduct.label"
            id="label"
            placeholder="Masukkaan Label Produk"
          />
        </b-form-group>

        <b-form-group
          v-if="formProduct.jenis === 'PREMIUM'"
          label="Harga"
          label-for="harga"
        >
          <b-form-input
            v-model="formProduct.harga"
            id="harga"
            placeholder="Masukkaan Harga Produk"
          />
        </b-form-group>

        <b-form-group
          v-if="formProduct.jenis === 'PREMIUM'"
          label="Harga Diskon"
          label-for="hargaDiskon"
        >
          <b-form-input
            v-model="formProduct.harga_diskon"
            id="hargaDiskon"
            placeholder="Masukkaan Harga Diskon Produk"
          />
        </b-form-group>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="show">Aktif</label>
          <b-form-checkbox
            id="show"
            :checked="formProduct.is_active"
            v-model="formProduct.is_active"
            class=""
            name="check-button"
            switch
          />
        </div>
      </b-form>
    </b-modal>

    <!-- Add Video -->
    <b-modal
      id="Tambah Video"
      size="md"
      centered
      ref="modalAddDetails"
      title="Tambahkan Video"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="resetModalDetail"
      @hidden="resetModalDetail"
      @ok="submitDetailProduct"
    >
      <b-form>
        <b-form-group label="Nama Video" label-for="nama">
          <b-form-input
            v-model="formAddDetail.nama"
            id="nama"
            placeholder="Masukkaan Nama Video"
          />
        </b-form-group>
        <b-form-group label="Link Video" label-for="link">
          <b-form-input
            v-model="formAddDetail.link"
            id="link"
            placeholder="Masukkaan Link Video"
          />
        </b-form-group>

        <b-form-group label="Deskripsi Video" label-for="deskripsi">
          <b-form-input
            v-model="formAddDetail.deskripsi"
            id="deskripsi"
            placeholder="Masukkaan Deskripsi Video"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Delete product -->
    <b-modal
      id="hapus-produk"
      cancel-variant="outline-secondary"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-title="Close"
      centered
      title="Hapus Produk"
      @ok="deleteProduct"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda akan menghapus Produk ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import CustomFilter from '@/@core/components/base/CustomFilter.vue'
import SearchInput from '@/@core/components/base/SearchInput.vue'
import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import { BButton, BTable, BDropdown, BDropdownItem, BCard, BSpinner, BFormInput, BModal, BForm, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Pagination from '@/@core/components/base/Pagination.vue'
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useRouter, formatDate, rupiah } from "@core/utils/utils";
import debounce from "lodash.debounce"
import vSelect from "vue-select";
import router from '@/router';

import repository from "@repofactory";
const repoProducts = repository.get("ProductsRepository")

export default defineComponent({
  components: {
    SearchInput,
    BButton,
    CustomFilter,
    BTable,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Pagination,
    BCard,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox
  },
  setup() {
    const toast = useToast();

    const data = ref([])
    const isLoading = ref(false)
    const isLoadingSubmit = ref(false)

    const formProduct = ref({
      nama: null,
      jenis: null, // GRATIS || PREMIUM
      category: null, // SAINTEK || SOSHUM || SKD
      harga: 0,
      label: null,
      product_type: "video",
      is_active: false,
      harga_diskon: 0
    });

    const product_id = ref(null)

    const setTargetedId = (value) => {
      product_id.value = value
    }

    const formAddDetail = ref({
      nama: null,
      link: null,
      deskripsi: null,
      preview_image: null
    })

    const modalProduct = ref(null)
    const modalAddDetails = ref(null)

    const tableColumns = [
      { key: "id", label: "ID" },
      { key: "nama", label: "NAMA" },
      { key: "category", label: "KATEGORI" },
      { key: "jenis", label: "JENIS" },
      { key: "label", label: "LABEL" },
      { key: "harga", label: "HARGA" },
      { key: "harga_diskon", label: "HARGA DISKON" },
      { key: "created_at", label: "DIBUAT" },
      { key: "updated_at", label: "DIUBAH" },
      { key: "is_active", label: "STATUS" },
      { key: "actions" },
    ]

    const keyword = ref("")
    const filterList = ref([
      {
        name: "Kategori",
        list: []
      }
    ])

    const initialValue = ref({
      "Mata Pelajaran": null,
      "Kategori": null,
      "Paket": null
    })

    const filterValue = ref({...initialValue})

    let totalPage = ref(1);
    let totalData = ref(1);
    let from = ref(1);
    let to = ref(1);

    const listJenis = ref([
      {
        label: "GRATIS",
        value: "GRATIS"
      },
      {
        label: "PREMIUM",
        value: "PREMIUM"
      }
    ])

    const listCategory = ref([
      {
        label: "SAINTEK",
        value: "SAINTEK"
      },
      {
        label: "SOSHUM",
        value: "SOSHUM"
      },
      {
        label: "SKD",
        value: "SKD"
      }
    ])

    const onSearch = (e) => {
      keyword.value = e.currentTarget.value
    }

    const handleChange = (value) => {
      filterValue.value = {...value}
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const fetchData = async () => {
      try {
        isLoading.value = true
        const res = await repoProducts.get("video", keyword.value)
        data.value = res.data.data
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error Fetching data products",
          "danger",
          "AlertTriangleIcon"
        );
        }

        isLoading.value = false
      }
    }

    const fetchProduct = async () => {
      try {
        const res = await repoProducts.getDetail(product_id.value)
        const data = res.data.data
        formProduct.value = {
          ...formProduct.value,
          nama: data.nama,
          category: data.category,
          jenis: data.jenis,
          harga: data.harga,
          harga_diskon: data.harga_diskon,
          label: data.label,
          is_active: !!data.is_active,
        }

      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error Fetching data product",
          "danger",
          "AlertTriangleIcon"
        );
        }
      }
    }


    const submitProduct = async (bvModalEvent) => {
      bvModalEvent.preventDefault()
      try {
        isLoadingSubmit.value = true
        const res = await repoProducts.create(formProduct.value)
        product_id.value = res.data.data.id
        modalAddDetails.value.show()
        modalProduct.value.hide()
        showToast("Notifikasi", res.data.message, "primary");
      } catch (e) {
        isLoadingSubmit.value = false
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
            showToast(
            "Error",
            "Error Submit data products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false
      }
    }

    const editProduct = async (bvModalEvent) => {
      bvModalEvent.preventDefault()
      try {
        isLoadingSubmit.value = true
        const res = await repoProducts.update(product_id.value, formProduct.value)

        bvModalEvent.vueTarget.hide()
        showToast("Notifikasi", res.data.message, "primary");
        fetchData()
      } catch (e) {
        isLoadingSubmit.value = false
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
            showToast(
            "Error",
            "Error Edit data products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false
      }
    }

    const submitDetailProduct = async (modal) => {
      try {
        modal.preventDefault()
        isLoadingSubmit.value = true
        const res = await repoProducts.createDetail(product_id.value, formAddDetail.value)
        showToast("Notifikasi", res.data.message, "primary");
        modalAddDetails.value.hide()
        router.push(`/video-detail/${product_id.value}`)
      } catch (e) {
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
            showToast(
            "Error",
            "Error Submit data products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false
      }
    }

    const deleteProduct = async (modal) => {
      try {
        isLoadingSubmit.value = true
        modal.preventDefault()
        const res = await repoProducts.delete(product_id.value)
        showToast("Notifikasi", res.data.message, "primary");
        // modal.hide()
        modal.vueTarget.hide()
        fetchData()
      } catch (e) {
        if(e.response.data.message) {
            showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
            showToast(
            "Error",
            "Error Submit data products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false
      }
    }

    onMounted(() => {
      fetchData()
    })

    watch([keyword], debounce(fetchData, 500));

    const resetModal = () => {
      formProduct.value = {
        nama: "",
        jenis: null,
        category: null,
        harga: 0,
        label: null,
        harga_diskon: 0,
        product_type: "video",
        is_active: false,
      }
    }

    const resetModalDetail = () => {
      formAddDetail.value = {
        nama: null,
        link: null,
        deskripsi: null,
        preview_image: null
      }
    }

    return {
      // state
      data,
      keyword,
      filterList,
      initialValue,
      filterValue,
      tableColumns,
      totalPage,
      totalData,
      from,
      to,
      formProduct,
      listJenis,
      listCategory,
      isLoading,
      formAddDetail,
      modalAddDetails,
      modalProduct,
      isLoadingSubmit,
      product_id,

      // method
      onSearch,
      handleChange,
      formatDate,
      rupiah,
      resetModal,
      submitProduct,
      resetModalDetail,
      submitDetailProduct,
      setTargetedId,
      deleteProduct,
      fetchProduct,
      editProduct,
    }
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
