import Video from "@/views/dashboard/manajemen-video/video/index.vue";
import DetailVideo from "@/views/dashboard/manajemen-video/video/detail.vue";
import PreviewVideo from "@/views/dashboard/manajemen-video/video/preview.vue";
export default [
  // Gratis
  {
    path: "/video",
    name: "video",
    component: Video,
    meta: {
      pageTitle: "Manajemen Video",
      breadcrumb: [
        {
          text: "Video",
          active: true,
        },
      ],
    },
  },

  {
    path: "/video-detail/:id",
    name: "detail-video",
    component: DetailVideo,
    meta: {
      pageTitle: "Manajemen Video",
      breadcrumb: [
        {
          text: "Video",
          active: false,
          to: {path: "/video"},
        },
        {
          text: "List Video",
          active: true,
        },
      ],
    },
  },

  {
    path: "/video-detail/:id/preview/:detail_id",
    name: "preview-video",
    component: PreviewVideo,
    meta: {
      pageTitle: "Manajemen Video",
    },
  },
];
