export default [
  {
    path: "/users",
    name: "manajemen-users",
    meta: {
      pageTitle: "Manajemen Pengguna",
      breadcrumb: [
        {
          text: "Pengguna",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/user/users-list/UsersList.vue"),
  },
  {
    path: "/user/:id",
    name: "manajemen-users-detail",
    meta: {
      pageTitle: "Manajemen Pengguna",
      breadcrumb: [
        {
          text: "Pengguna",
          active: true,
        },
        {
          text: ":id",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/user/users-list/detail.vue"),
  },
  {
    path: "/user/history/:id",
    name: "manajemen-users-tryout-history",
    meta: {
      pageTitle: "Manajemen Pengguna",
      breadcrumb: [
        {
          text: "Riwayat Tryout",
          active: true,
        },
        {
          text: ":id",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/dashboard/user/users-list/UserTryoutHistory.vue"),
  },
  {
    path: "/user-edit/:id",
    name: "manajemen-users-edit",
    meta: {
      pageTitle: "Manajemen Pengguna",
      breadcrumb: [
        {
          text: "Pengguna",
          active: true,
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/user/users-list/Edit.vue"),
  },
];
