import Client from "./Clients/AxiosClient";
const resource = "/api/v1/soal";

export default {
  get(page = 1, word = "", mataPelajaran = "", kategoriSoal = "", paket = "") {
    let filters = "filters=";

    if (!!mataPelajaran) {
      filters += `mata_pelajaran:${mataPelajaran}`;
    }

    if (!!kategoriSoal) {
      filters += `${!!mataPelajaran ? "," : ""}kategori_soal:${kategoriSoal}`;
    }

    if (!!paket) {
      filters += `${!!kategoriSoal ? "," : ""}paket:${paket}`;
    }
    return Client.get(
      `${resource}?page=${page}&per_page=20&search=${word}&${filters}`
    );
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.post(`${resource}/${id}?_method=PUT`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  importExcel(payload) {
    return Client.post(`${resource}/import-excel`, payload);
  },
};
