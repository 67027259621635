import Live from "@/views/dashboard/manajemen-video/live/index";
import DetailLive from "@/views/dashboard/manajemen-video/live/detail";

export default [
  {
    path: "/live",
    name: "live",
    component: Live,
    meta: {
      pageTitle: "Manajemen Video",
      breadcrumb: [
        {
          text: "Live",
          active: true,
        },
      ],
    },
  },

  {
    path: "/live-detail/:id",
    name: "detail-live",
    component: DetailLive,
    meta: {
      pageTitle: "Manajemen Video",
      breadcrumb: [
        {
          text: "Live",
          active: false,
          to: {name: "live"},
        },
        {
          text: "List Live",
          active: true,
        },
      ],
    },
  },
];
