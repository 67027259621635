export default [
  {
    path: "/manajemen-bundle",
    name: "manajemen-bundle",
    meta: {
      pageTitle: "Manajemen Bundle",
      breadcrumb: [
        {
          text: "Bundle",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-bundle"),
  },
  {
    path: "/manajemen-bundle/:id",
    name: "manajemen-bundle-detail",
    meta: {
      pageTitle: "Detail Bundle",
      breadcrumb: [
        {
          text: "Bundle",
          to: {name: "/manajemen-bundle"},
          active: false,
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-bundle/detail.vue"),
  },
];
