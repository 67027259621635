import Client from "./Clients/AxiosClient";
const resource = "/api/v1/admin/paket-tryout";

export default {
  get(search, page, kategori) {
    let link = `${resource}?search=${search}&per_page=10&page=${page}`;
    if (!!kategori) link += "&kategori_tryout_id=" + kategori;
    return Client.get(link);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
