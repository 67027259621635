export default [
  {
    path: '/kampus',
    name: 'manajemen-kampus',
    meta: {
      pageTitle: 'Manajemen Kampus',
      breadcrumb: [
        {
          text: 'Kampus',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/manajemen-kampus/Kampus.vue'),
  },
  {
    path: '/kampus/:id',
    name: 'manajemen-jurusan',
    meta: {
      pageTitle: 'Manajemen Jurusan',
      breadcrumb: [
        {
          text: 'Kampus',
          active: true,
        },
        {
          text: 'Jurusan',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/manajemen-kampus/Jurusan.vue'),
  },
  {
    path: '/kampus/:id_kampus/jurusan/:id_jurusan',
    name: 'manajemen-daya-tampung',
    meta: {
      pageTitle: 'Manajemen Daya Tampung',
      breadcrumb: [
        {
          text: 'Kampus',
          active: true,
        },
        {
          text: 'Jurusan',
          active: true,
        },
        {
          text: 'Daya Tampung',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/manajemen-kampus/DayaTampung.vue'),
  },
]
