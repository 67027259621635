import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import usersRoute from "@/router/users";
import manajemenSoal from "@/router/bank-soal";
import manajemenKampus from "./manajemen-kampus";
import manajemenEvent from "./manajemen-event";
import manajemenBundle from "./manajemen-bundle";
import manajemenKomunitas from "./manajemen-komunitas";
import accessControl from "@/router/access-control";
import paketTryout from "@/router/manajemen-try-out";
import modul from "@/router/modul";
import dashboard from "@/router/dashboard";
import RiwayatPembayaran from "@/router/invoice";
import video from "@/router/manajemen-video/video";
import live from "@/router/manajemen-video/live";
import voucher from "@/router/voucher";

import repository from "@repofactory";
import ebook from "./manajemen-materi/ebook";
import ringkasanMateri from "./manajemen-materi/ringkasan-materi";
const authRepo = repository.get("auth");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: [
    ...dashboard,
    ...usersRoute,
    ...manajemenSoal,
    ...accessControl,
    ...paketTryout,
    ...modul,
    ...manajemenKampus,
    ...manajemenKomunitas,
    ...RiwayatPembayaran,
    ...video,
    ...manajemenEvent,
    ...manajemenBundle,
    ...live,
    ...ebook,
    ...ringkasanMateri,
    ...voucher,
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "preview-video-gratis") {
    to.meta.breadcrumb = [
      {
        text: "Video Gratis",
        active: false,
        to: {path: "/video/gratis"},
      },
      {
        text: "List Video Gratis",
        active: false,
        to: {path: `/video-detail/${to.params.id}`},
      },
      {
        text: "Preview Video",
        active: true,
      },
    ];
  }

  if (to.name === "preview-ebook") {
    to.meta.breadcrumb = [
      {
        text: "Ebook",
        active: false,
        to: {path: "/ebook"},
      },
      {
        text: "List Ebook",
        active: false,
        to: {path: `/ebook-detail/${to.params.id}`},
      },
      {
        text: "Preview Ebook",
        active: true,
      },
    ];
  }

  const isLogin = store.state.auth.isLogin;

  if (to.name !== "login" && !isLogin) {
    if (localStorage.getItem("token")) {
      authRepo.user().then(response => {
        store.state.auth.isLogin = true;
        store.state.auth.token = localStorage.getItem("token");
        store.state.auth.user = response.data.user;
        store.state.auth.permissions = response.data.user.permissions;
      });

      next();
    } else {
      next({name: "login"});
    }
  } else {
    next();
  }
});

// router.bef

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
