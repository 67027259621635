<template>
  <div>
    <iframe
      class="tw-w-full"
      height="450"
      :src="data.link"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>

    <h2
      class="tw-font-bold tw-text-[24px] tw-leading-[32px] tw-mt-[36px] tw-mb-[23px]"
    >
      Detail Video
    </h2>

    <div
      class="tw-rounded-[30px] tw-bg-white tw-text-[20px] tw-px-[27px] tw-py-[29px] tw-shadow-xl"
    >
      <div class="tw-grid tw-gap-3 sm:tw-grid-cols-2 tw-grid-cols-1">
        <div class="">
          <h3 class="tw-font-bold">Judul Video</h3>
          <p class="tw-text-[#6F7981]">{{data.nama}}</p>
        </div>

        <div class="">
          <h3 class="tw-font-bold">Deskripsi</h3>
          <p class="tw-text-[#6F7981]">{{data.deskripsi}}</p>
        </div>
      </div>

      <div class="tw-grid tw-gap-3 md:tw-grid-cols-2 tw-grid-cols-1">
        <div class="">
          <h3 class="tw-font-bold">Link Video</h3>
          <p class="tw-text-[#6F7981] tw-break-all">{{data.link}}</p>
        </div>

        <div class="">
          <h3 class="tw-font-bold">Kategori Video</h3>
          <p class="tw-text-[#6F7981]">{{data.deskripsi}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import repository from "@repofactory";
const repoProducts = repository.get("ProductsRepository")

import { useRouter } from "@core/utils/utils";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default defineComponent({
    setup() {
        const { route } = useRouter();
        const toast = useToast();

        const data = ref(null)

        const showToast = (title, text, variant, icon = "BellIcon") => {
            toast({
                component: ToastificationContent,
                props: {
                title,
                icon,
                text,
                variant,
                },
            });
        };

        const fetchData = async () => {
            try {
                const res = await repoProducts.getDetailsDetail(route.value.params.id, route.value.params.detail_id)
                data.value = res.data.data
            } catch (e) {
                if(e.response.data.message) {
                    showToast(
                    "Error",
                    e.response.data.message,
                    "danger",
                    "AlertTriangleIcon"
                );
                } else {
                    showToast(
                    "Error",
                    "Error Set Type Products",
                    "danger",
                    "AlertTriangleIcon"
                    );
                }
            }
        }
        onMounted(() => {
            fetchData()
        })

        return {
            data,
        }
    },
})
</script>
