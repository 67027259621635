export default [
  {
    path: "/manajemen-event",
    name: "manajemen-event",
    meta: {
      pageTitle: "Manajemen Event",
      breadcrumb: [
        {
          text: "Event",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-event"),
  },
  {
    path: "/manajemen-event/:id",
    name: "manajemen-event-detail",
    meta: {
      pageTitle: "Detail Event",
      breadcrumb: [
        {
          text: "Event",
          active: false,
          to: {path: "/manajemen-event"},
        },
        {
          text: "Event Detail",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-event/detail"),
  },
];
