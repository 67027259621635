import userRepository from "./UserRepository";
import auth from "./auth";
import kategoriSoalRepository from "./KategoriSoalRepository";
import kategoriTryoutRepository from "./KategoriTryoutRepository";
import paketTryoutRepository from "./PaketTryoutRepository";
import soalRepository from "./SoalRepository";
import mapelRepository from "./MapelRepository";
import paketMapelRepository from "./PaketMapelRepository";
import paketKategoriRepository from "./PaketKategoriRepository";
import materiDokumenRepository from "./MateriDokumenRepository";
import KampusRepository from "./KampusRepository";
import JurusanRepository from "./JurusanRepository";
import DayaTampungRepository from "./DayaTampungRepository";
import InvoiceRepository from "./Invoice";
import BayarRepository from "./Bayar";
import ProductsRepository from "./ProductsRepository";
import HasilTryoutRepositories from "./HasilTryoutRepositories";

//Daerah
import ProvinsiRepository from "./ProvinsiRepository";
import KotaRepository from "./KotaRepository";
import KecamatanRepository from "./KecamatanRepository";

// Dashboard
import DashboardRepository from "./DashboardRepository";

// Jenis
import JenisRepository from "./JenisRepository";

// Event
import EventRepository from "./EventRepository";

// Bundle
import BundleRepository from "./BundleRepository";

import VoucherRepository from "./VoucherRepository";

const repositories = {
  userRepository,
  auth,
  kategoriSoalRepository,
  soalRepository,
  mapelRepository,
  paketKategoriRepository,
  paketMapelRepository,
  kategoriTryoutRepository,
  paketTryoutRepository,
  materiDokumenRepository,
  KampusRepository,
  JurusanRepository,
  DayaTampungRepository,
  InvoiceRepository,
  BayarRepository,
  ProductsRepository,
  HasilTryoutRepositories,

  //Daerah
  ProvinsiRepository,
  KotaRepository,
  KecamatanRepository,

  // Dashboard
  DashboardRepository,

  // Jenis
  JenisRepository,

  // Event
  EventRepository,

  // Bundle
  BundleRepository,

  VoucherRepository,
};
export default {
  get: name => repositories[name],
};
