export default [
  {
    path: "/komunitas",
    name: "komunitas",
    meta: {
      pageTitle: "Manajemen Komunitas",
      breadcrumb: [
        {
          text: "Komunitas",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-komunitas/komunitas/index.vue"),
  },
  {
    path: "/komunitas/:id",
    name: "detail-komunitas",
    meta: {
      pageTitle: "Manajemen Komunitas",
      breadcrumb: [
        {
          text: "Komunitas",
          active: false,
          to: {name: "komunitas"},
        },
        {
          text: "List Komunitas",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-komunitas/komunitas/detail.vue"),
  },
];
