import Vue from "vue";
import {ToastPlugin, ModalPlugin} from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import vuescroll from "vuescroll";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

//scrollbar
Vue.use(vuescroll);

// import core styles
require("@core/scss/core.scss");
require("./assets/css/output.css");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
