export default [
  {
    path: '/pembayaran/riwayat-pembayaran',
    name: 'pembayaran/riwayat-pembayaran',
    meta: {
      pageTitle: 'Pembayaran',
      breadcrumb: [
        {
          text: 'Riwayat Pembayaran',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/Pembayaran/RiwayatPembayaran.vue'),
  },
  {
    path: '/pembayaran/riwayat-pembayaran/:id',
    name: 'pembayaran/riwayat-pembayaran-detail',
    meta: {
      pageTitle: 'Pembayaran',
      breadcrumb: [
        {
          text: 'Riwayat Pembayaran',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/Pembayaran/RiwayatPembayaranDetail.vue'),
  },
]
