import Client from "./Clients/AxiosClient";
const resource = "/api/v1/paket-mapel";

export default {
  get(page = 1, keyword = "", mata_pelajaran = "", perPage) {
    let filters = "&filters=";
    if (!!mata_pelajaran) {
      filters += `mata_pelajaran:${mata_pelajaran}`;
    }
    return Client.get(
      `${resource}?page=${page}&per_page=${perPage}&search=${keyword}${filters}`
    );
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}/create`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  update(payload, id) {
    return Client.post(`${resource}/${id}?_method=PUT`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
