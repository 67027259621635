export default [
  {
    path: "/manajemen-paket/mapel",
    name: "manajemen-paket-mapel",
    meta: {
      pageTitle: "Paket Mapel",
      breadcrumb: [
        {
          text: "Paket Mapel",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/dashboard/bank-soal/paket-mapel/index.vue"),
  },
  {
    path: "/manajemen-paket/mapel/:id",
    name: "manajemen-paket-mapel-detail",
    meta: {
      pageTitle: "Manajemen Paket",
      breadcrumb: [
        {
          text: "Paket Mapel",
          active: false,
          to: "/manajemen-paket/mapel",
        },
        {
          text: "Preview",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/dashboard/bank-soal/paket-mapel/detail.vue"),
  },
  {
    path: "/manajemen-paket/kategori",
    name: "manajemen-paket-kategori",
    meta: {
      pageTitle: "Paket Kategori",
      breadcrumb: [
        {
          text: "Bank Soal",
          active: true,
        },
        {
          text: "Manajemen Paket",
          active: true,
        },
        {
          text: "Paket Kategori",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/dashboard/bank-soal/paket-kategori/index.vue"),
  },
];
