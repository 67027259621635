<template>
  <div class="filter">
    <div class="section border_right">
      <img src="/assets/images/filter.png" alt="filter" />
    </div>
    <div class="section border_right"><p>Filter By</p></div>
    <button
      v-for="item in data"
      :key="item.name"
      class="section border_right filter_dropdown"
      @click="toggleDropdown(item.name)"
    >
      <p>{{ item.name }}</p>
      <img src="/assets/images/chevron-down.png" alt="chevron-down" />
    </button>
    <button class="section filter_dropdown filter_reset" @click="onReset">
      <img src="/assets/images/reset.png" alt="reset" />
      <p>Reset Filter</p>
    </button>

    <!-- Dropdown list -->
    <ul
      class="tw-w-full tw-max-h-[200px] tw-overflow-y-scroll"
      v-if="isClicked && !!clickedCategory.list.length"
    >
      <li
        v-for="item in clickedCategory.list"
        :key="item.label"
        @click="onChange(item.label)"
        :class="(value[clickedCategory.name] || '').toLowerCase() === item.label.toLowerCase() && 'tw-bg-[#5022e2] tw-text-white'"
      >
        {{item.label}}
      </li>
    </ul>

    <div
      v-else-if="isClicked && !!!clickedCategory.list.length"
      class="tw-w-full tw-h-[50px] tw-flex tw-justify-center tw-items-center empty"
    >
      <p class="tw-font-bold">{{`Data ${clickedCategory.name} Tidak Ada`}}</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isClicked: false,
      clickedCategory: {},
      value: this.filterValue,
    }
  },
  props: {
    data: Array,
    handleChange: Function,
    filterValue: Object,
    initialValue: Object,
  },
  methods: {
    toggleDropdown (value) {
      this.isClicked = !this.isClicked
      this.clickedCategory = this.data.find(item => item.name === value)
    },
    onChange(value) {
      const newFilterValue = {
        ...this.value,
        [this.clickedCategory.name]: this.value[this.clickedCategory.name] === value ? null : value
      }
      this.handleChange(newFilterValue);
      this.value = {...newFilterValue};
    },
    onReset() {
      this.handleChange(this.initialValue);
      this.value = this.initialValue
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
button {
  border: none;
  background: transparent;
}

ul, .empty {
  min-width: 280px;
  border-radius: 8px;
  box-shadow: 0px 4px 14px 0px #0000001A;
  position: absolute;
  z-index: 1;
  top: 60px;
  background: #FFFFFF;
  padding: 0;
}

ul li {
  list-style: none;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
}

ul li:hover {
  background: #5022e2;
  color: white;
}

.filter {
  display: flex;
  min-width: 412px;
  height: 48px;
  align-items: center;
  border: 0.41px solid rgba(213, 213, 213, 1);
  background: rgba(249, 249, 251, 1);
  border-radius: 15px;
  color: #202224;
  position: relative;
}

.filter .section {
  padding: 14px 12px;
}

.filter .border_right {
  border-right: 0.41px solid rgba(213, 213, 213, 1);
}

.filter .filter_dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.filter .filter_dropdown p {
  margin: 0;
}

.filter .filter_reset {
  color: #EA0234;
}
</style>
