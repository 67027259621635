export default [
  {
    path: '/modul/tps',
    name: 'modul/tps',
    meta: {
      pageTitle: 'Modul',
      breadcrumb: [
        {
          text: 'Modul',
          active: true,
        },
        {
          text: 'TPS',
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/modul/Index.vue'),
  },
]
