import Client from "./Clients/AxiosClient";
const resource = "/api/v1/admin/users";

export default {
  get(page = 1, search = "", perPage = 0, filters) {
    let filtersString = "";

    const cabang = filters["Cabang"];
    const gender = filters["Gender"];
    const kategori = filters["Kategori"];

    if (!!cabang) {
      filtersString += `cabang:${cabang}`;
    }

    if (!!gender) {
      filtersString += `${!!cabang ? "," : ""}jenis_kelamin:${gender}`;
    }

    if (!!kategori) {
      filtersString += `${!!gender ? "," : ""}is_murid_priority:${
        kategori === "Priority" ? 1 : 0
      }`;
    }

    return Client.get(`${resource}`, {
      params: {
        page,
        search,
        per_page: perPage,
        filters: filtersString,
      },
    });
  },
  search(search) {
    return Client.get(`${resource}?search=${search}`);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.post(`${resource}/${id}?_method=PUT`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  resetPassword(email) {
    return Client.post(`${resource}/reset-password`, {
      email,
    });
  },
};
