<template>
  <div
    class="search-container tw-w-full tw-h-[48px] tw-flex tw-flex-1 tw-gap-[10px] tw-items-center tw-rounded-[15px] tw-border tw-px-[16px] tw-py-[12px] tw-bg-[#FFFFFF]"
  >
    <img src="/assets/images/search.svg" alt="search" />
    <input
      class="tw-border-none search tw-text-[16px] tw-flex-1"
      :placeholder="placeholder || 'Search..'"
      type="text"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    onInput: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.search-container {
  border: 1px solid #e9ecef;
}

.search::placeholder {
  color: #adb5bd;
  opacity: 1; /* Firefox */
}

.search:focus {
  outline: none;
}

.search::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #adb5bd;
}
</style>
