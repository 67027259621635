import Client from "./Clients/AxiosClient";
const base = "/api/v1/admin/jenis";

export default {
  get() {
    return Client.get(`${base}`);
  },
  detaiil(id) {
    return Client.get(`${base}/${id}`);
  },
  create(payload) {
    return Client.post(base, payload);
  },
  update(id, payload) {
    return Client.put(`${base}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${base}/${id}`);
  },
};
