import Ebook from "@/views/dashboard/manajemen-materi/ebook/index.vue";
import DetailEbook from "@/views/dashboard/manajemen-materi/ebook/detail.vue";
import PreviewEbook from "@/views/dashboard/manajemen-materi/ebook/preview.vue";

export default [
  //
  {
    path: "/ebook",
    name: "ebook",
    component: Ebook,
    meta: {
      pageTitle: "Manajemen Materi",
      breadcrumb: [
        {
          text: "Ebook",
          active: true,
        },
      ],
    },
  },

  {
    path: "/ebook-detail/:id",
    name: "detail-ebook",
    component: DetailEbook,
    meta: {
      pageTitle: "Manajemen Materi",
      breadcrumb: [
        {
          text: "Ebook",
          active: false,
          to: {name: "ebook"},
        },
        {
          text: "List Ebook",
          active: true,
        },
      ],
    },
  },

  {
    path: "/ebook-detail/:id/preview/:detail_id",
    name: "preview-ebook",
    component: PreviewEbook,
    meta: {
      pageTitle: "Manajemen Materi",
    },
  },
];
